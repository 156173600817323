<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
  <v-card flat>
    <v-card-title
      class="black--text"
      style="margin-bottom: 20px; margin-top: 20px"
      >{{ $t("scadenze.elencoScadenzeAttive") }}
    </v-card-title>
    <v-row>
      <v-col>
        <v-data-table
          style="max-height:200px; overflow:auto"
          id="tblScadenze"
          dense
          hide-default-footer
          :headers="headersScadenze"
          :items="eScadenze"
          :value="[scadenzaSel]"
          @click:row="onTableRowScadenzaClick"
          single-select
          item-key="guid"
          class="elevation-1"
          loading-text="Caricamento dati in corso..."
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDeleteScadenza" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Sei sicuro di voler cancellare l'elemento
                  selezionato?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteScadenza"
                    >Annulla</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirmScadenza"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-show="!shouldDisable"
              small
              @click="deleteItemScadenza(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-row>
      <v-col>
        <v-card-title
          class="black--text"
          style="margin-bottom: 20px; margin-top: 20px"
        >
          {{ $t("scadenze.datiScadenze") }}
        </v-card-title>
      </v-col>
      <v-col style="margin-top: 10px" align="end">
        <v-btn
          tile
          color="darkred"
          :disabled="shouldDisable"
          @click="onNuovaScadenzaClick"
        >
          <v-icon left> mdi-plus-circle-outline </v-icon>
          {{ $t("scadenze.nuovaScadenza") }}
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="scadenzaSel != null">
    <v-row>
      <v-col>
        <v-select
          :items="t_tiposcadenze"
          return-object
          item-text="cDescrizione"
          item-value="id"
          :label="$t('scadenze.tipoScadenza')"
          v-model="scadenzaSel.scadenzaTipo"
          :disabled="shouldDisableScad"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.scadenzaTipo.tScadenzaCategoria.cDescrizione"
          :label="$t('scadenze.tipoScadenza.categoria')"
          :disabled="true"
        >
        </v-text-field>
      </v-col>

      <v-col>
        <v-select
          :items="t_tipointerventi"
          return-object
          item-text="cDescrizione"
          item-value="id"
          :label="$t('scadenze.tipoIntervento')"
          v-model="scadenzaSel.interventoTipo"
          :disabled="shouldDisableScad"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="scadenzaSel.interventoTipo.flagFiscale"
          :label="$t('scadenze.tipoIntervento.fiscale')"
          hide-details
          :disabled="true"
        >
          >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="t_conteggi"
          return-object
          item-text="cDescrizione"
          item-value="id"
          :label="$t('scadenze.conteggioSu')"
          v-model="scadenzaSel.scadenzaConteggio"
          :disabled="shouldDisableScad"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.nIntervallo"
          :label="$t('scadenze.intervallo')"
          type="number"
          min="1"
          max="999999"
          :rules="[rules.valueMin(scadenzaSel.nInventario, 0), rules.valueMax(scadenzaSel.nInventario, 999999)]"
          :disabled="shouldDisableScad"
          @keydown="onKeyDown"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.dataDecorrenzaText"
          :label="$t('scadenze.dataDecorrenza')"
          type="date"
          min="1990-01-01"
          max="2050-12-31"
          :disabled="shouldDisableScad"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.nKmOreDecor"
          :label="$t('kmOreDec')"
          type="number"
          min="0"
          max="999999"
          :disabled="shouldDisableScad"
          @keydown="onKeyDown"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="t_statoscadenza"
          return-object
          item-text="cDescrizione"
          item-value="id"
          :label="$t('scadenze.statoScadenza')"
          v-model="scadenzaSel.scadenzaStato"
          :disabled="shouldDisableScad"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="scadenzaSel.flagRinnovo"
          :label="$t('rinnovo')"
          hide-details
          :disabled="shouldDisableScad"
        >
          >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.dataGaranziaText"
          :label="$t('scadenze.dataScadenzaGaranzia')"
          type="date"
          min="1990-01-01"
          max="2050-12-31"
          :disabled="shouldDisableScad"
        >
        </v-text-field>
      </v-col>

      <v-col>
        <v-textarea
          v-model="scadenzaSel.xNote"
          outlined
          name="txtNoteScadenza"
          :label="$t('note')"
          :readonly="shouldDisable"
        >
        </v-textarea>
      </v-col>
    </v-row>
  
    <v-divider class="mx-4"></v-divider>
    <v-card-title
      class="black--text"
      style="margin-bottom: 20px; margin-top: 20px"
      >{{ $t("scadenze.scadenzaPrec") }}
    </v-card-title>
    <v-row>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.dataPrecedenteText"
          :label="$t('data')"
          type="date"
          min="1990-01-01"
          max="2050-12-31"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.nKmOrePrec"
          :label="$t('kmOre')"
          type="number"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.cNumBuonoPrec"
          :label="$t('numeroBuono')"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-card-title
      class="black--text"
      style="margin-bottom: 20px; margin-top: 20px"
      >{{ $t("scadenze.scadenzaCorr") }}
    </v-card-title>
    <v-row>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.dataCorrenteText"
          :label="$t('data')"
          type="date"
          min="1990-01-01"
          max="2050-12-31"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.nKmOreCor"
          :label="$t('kmOre')"
          type="number"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="scadenzaSel.cNumBuonoCor"
          :label="$t('numeroBuono')"
          :disabled="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
    </template>
    <v-row style="margin-top: 20px; margin-bottom: 10px">
      <v-col style="margin-top: 10px" align="start">
      <v-btn
        tile
        color="darkred"
        :disabled="shouldDisable"
        @click="onModificaScadClick"
      >
        <v-icon left> mdi-pencil </v-icon>
        {{ $t("scadenze.modificaDataScadenza") }}
      </v-btn>
      </v-col>
      <v-col style="margin-top: 10px" align="end">
        <v-btn
          tile
          color="darkred"
          :disabled="shouldDisable"
          @click="onSaveScadenzaClick"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("scadenze.salva") }}
        </v-btn>
        <!-- Modifica data scadenza -->
        <v-dialog v-model="dialogModificaDataScad" max-width="500px" width="300px">
          <v-card>
            <v-card-title class="text-h5"
              >Modifica data scadenza</v-card-title
            >
              <v-col>
                <v-text-field
                  v-model="newDataScad"
                  :label="$t('scadenze.dataScadenza')"
                  type="date"
                  min="1990-01-01"
                  max="2050-12-31"
                >
                </v-text-field>
              </v-col>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModificaDataScadenza"
                >Annulla</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="confirmModificaDataScadenza"
                >OK</v-btn
              >
              
              <v-spacer></v-spacer>
            </v-card-actions>
              <v-col cols="11">
              <v-alert
                id="alertDisattiva"
                shaped
                :value="isAlertModificaScadVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
              </v-col>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import ScadenzeService from "@/service/ScadenzeService";

@Component({
  props: {
    t_tiposcadenze: {
      type: Array,
      required: true,
    },
    t_tipointerventi: {
      type: Array,
      required: true,
    },
    t_conteggi: {
      type: Array,
      required: true,
    },
    t_statoscadenza: {
      type: Array,
      required: true,
    },
    eScadenze: [],
    shouldDisable: false,
  },
})
export default class CompScadenze extends Vue {
  // Variabili
  scadenzaTempl = null;
  scadenzaSel = null;
  guidScadenzaToDelete = null;
  dialogDeleteScadenza = false;
  dialogModificaDataScad = false;
  shouldDisableScad= true;

  newDataScad = null;
  isAlertModificaScadVisible = false;
  messaggioAlert = "";
  alertType = "error";

  rules = Utils.rules;

  headersScadenze = [
    {
      text: "Tipo scadenza",
      align: "start",
      value: "scadenzaTipo.cDescrizione",
    },
    { text: "Tipo intervento", value: "interventoTipo.cDescrizione" },
    { text: "Data decorrenza", value: "dataDecorrenzaText" },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDelScadenzeFilter,
    },
    { text: "Actions", value: "actions", sortable: false },
  ];

  created() {
    this.getTemplateScadenza();
  }

  hDelScadenzeFilter(value) {
    // Necessario per filtrare la griglia delle scadenze e non visualizzare quelle cancellate cancellate
    return value === null;
  }

  getTemplateScadenza() {
    var self = this;

    ScadenzeService.getTemplateScadenza(
      (resp) => {
        console.log("GetTemplateScadenza");
        console.log(resp);
        self.scadenzaTempl = resp;
        self.scadenzaSel = resp;
      },
      (err) => {
        console.log("Errore GetTemplateScadenza");
        console.log(err);
        return null;
      }
    );
  }

  onModificaScadClick() {
    if (Utils.isNullOrUndefined(this.scadenzaSel) || 
      this.scadenzaSel.id < 1) {
        this.$emit("send-messagge", "error", "scadenze.erroreCambioScadenza");
        return;
    }
    this.newDataScad = this.scadenzaSel.dataCorrenteText;
    this.dialogModificaDataScad = true;
  }

  closeModificaDataScadenza() {
    this.dialogModificaDataScad = false;
  }

  confirmModificaDataScadenza() {
    this.cambiaDataScadenza(this.scadenzaSel.id, this.newDataScad);
  }

  onSaveScadenzaClick() {
    // Verifiche preliminare prima del caricamento
    if (this.scadenzaTempl == null) {
      // KO
      // Invio il messaggio al padre
      this.$emit("send-messagge", "error", "erroreCaricamentoTemplateScadenza");
      return;
    }
    if (
      Utils.isNullOrUndefined(this.scadenzaSel.nIntervallo) ||
      Utils.isNullOrUndefined(this.scadenzaSel.scadenzaTipo) ||
      Utils.isNullOrUndefined(this.scadenzaSel.scadenzaConteggio) ||
      Utils.isNullOrUndefined(this.scadenzaSel.dataDecorrenzaText) ||
      this.scadenzaSel.nIntervallo == "" ||
      this.scadenzaSel.dataDecorrenzaText == "" ||
      this.scadenzaSel.scadenzaTipo.id <= 0 ||
      this.scadenzaSel.scadenzaTipo.tScadenzaCategoria.id <= 0 ||
      this.scadenzaSel.scadenzaStato.id <= 0 ||
      this.scadenzaSel.scadenzaConteggio.id <= 0 
    ) {
      // KO
      // Invio il messaggio al padre
      this.$emit("send-messagge", "error", "erroreAggiuntaScadenza");
      return;
    }
    
    if (
      parseInt(this.scadenzaSel.nIntervallo) <= 0 
    ) {
      this.$emit("send-messagge", "error", "erroreValoriScadenza");
      return;
    }
    if (this.scadenzaSel.scadenzaConteggio.id == 4 || this.scadenzaSel.scadenzaConteggio.id == 5)
    {
      if (Utils.isNullOrUndefined(this.scadenzaSel.nKmOreDecor) || this.scadenzaSel.nKmOreDecor <= 0)
      {
        this.$emit("send-messagge", "error", "erroreValoriKmDec");
        return;
      }
    }
    // Invio l'oggetto al padre
    this.$emit("load-scadenza", this.scadenzaSel);

    // Svuoto i campi dopo l'inserimento in griglia
    this.onNuovaScadenzaClick();
  }

  onTableRowScadenzaClick(scadenza, row) {
    this.scadenzaSel = scadenza;
    if (scadenza.id == 0)
    {
      this.shouldDisableScad = true;
      return;
    }
    if (!this.shouldDisable) this.shouldDisableScad = false;
  }

  onNuovaScadenzaClick() {
    this.scadenzaSel = Object.assign({}, this.scadenzaTempl);
    this.scadenzaSel.guid = Utils.createGuid();
    this.shouldDisableScad = false;
  }

  onKeyDown(key) {
    if (key.key == "." || key.key == "," || key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  deleteItemScadenza(item) {

    this.guidScadenzaToDelete = item.guid;
    this.dialogDeleteScadenza = true;
  }

  closeDeleteScadenza() {
    this.dialogDeleteScadenza = false;
  }

  deleteItemConfirmScadenza() {
    this.$emit("delete-scadenza", this.guidScadenzaToDelete);
    this.dialogDeleteScadenza = false;
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertModificaScadVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertModificaScadVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// *****************
  /// DATABASE
  /// *****************

  cambiaDataScadenza(idScad, nuovaDataScadenza) {
    var self = this;

    ScadenzeService.cambiaDataScadenza(
      idScad, 
      nuovaDataScadenza,
      (resp) => {
        console.log("cambiaDataScadenza");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          self.dialogModificaDataScad = false;
          self.scadenzaSel.dataCorrenteText = nuovaDataScadenza;
          // Invio la modifica all'oggetto padre
          this.$emit("update-datascadenza", self.scadenzaSel);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }
}
</script>

<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
    <v-container id="ATTREZZATURE" fluid tag="section">
      <v-row>
        <v-col :cols="isEditattrezzatura ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-toolbox"
            :title="$t('attrezzature.titolo')"
            class="px-5 py-3"
            backgroundcolor=""
            titlecolor="red"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('attrezzature.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="visualizzaStorico"
                    :label="$t('visualizzaStorico')"
                    hide-details
                    @change="checkStorico($event)"
                  >
                    >
                  </v-checkbox>
                </v-col>
                <v-col align="end">
                  <v-btn
                    color="darkred"
                    width="180px"
                    @click="onNuovaAttrezzatura"
                  >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("attrezzature.nuovaAttrez") }}
                  </v-btn>
                  <v-btn color="darkred" width="180px" @click="onExportGrid">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMainAttrezz"
              :items="attrezzature.source"
              :headers="headersColumns"
              :search="search"
              :value="[attrezzaturaSel]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_attrezzature"
              loading-text="Caricamento dati in corso..."
            >
            </v-data-table>
          </base-material-card>
        </v-col>

        <v-col v-if="isEditattrezzatura" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-toolbox"
            :title="
              $t('page.attrezzatura.titolo') + ': ' + attrezzaturaSel.cSigla
            "
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab
                v-for="item in attrezzatureTabs"
                :key="item"
                @change="getTabSelected(item)"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in attrezzatureTabs" :key="item" eager>
                <v-card v-if="item == 'Anagrafica'" flat>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.cSigla"
                        :label="$t('sigla')"
                        maxlength="10"
                        :rules="[rules.required]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.nInventario"
                        :label="$t('inventario')"
                        type="number"
                        min="1"
                        max="999999"
                        :rules="[rules.valueMin(attrezzaturaSel.nInventario, 0), rules.valueMax(attrezzaturaSel.nInventario, 999999)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.cMarca"
                        :label="$t('marca')"
                        maxlength="50"
                        :rules="[rules.required]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.cModello"
                        :label="$t('modello')"
                        maxlength="50"
                        :rules="[rules.required]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.cDescrizione"
                        :label="$t('attrezzature.descrizione')"
                        maxlength="100"
                        :rules="[rules.required]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="attrezzaturaSel.flagUsoIndustriale"
                        :label="$t('usoIndustriale')"
                        hide-details
                        :disabled="shouldDisable"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="eEnti"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :label="$t('proprietario')"
                        v-model="attrezzaturaSel.enteDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="enteShouldDisable"
                        @change="onEnteChanged($event)"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="eCapitoliSpesa"
                        return-object
                        item-text="numCapitoloDescrizione"
                        item-value="id"
                        :label="$t('attrezzature.capitolo')"
                        v-model="attrezzaturaSel.capitoloSpesaDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="attrezzaturaSel.nAnnoAcquisto"
                        :label="$t('attrezzature.annoAcquisto')"
                        type="number"
                        min="1970"
                        max="2040"
                        :rules="[rules.required, rules.valueMin(attrezzaturaSel.nAnnoAcquisto, 1970), rules.valueMax(attrezzaturaSel.nAnnoAcquisto, 2040)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDownDecimalAndNegative"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-col>
                        <v-select
                          :items="eUbicazioni"
                          return-object
                          item-text="cDescrizione"
                          item-value="id"
                          :label="$t('attrezzature.ubicazione')"
                          v-model="attrezzaturaSel.ubicazioneDTO"
                          :rules="[rules.selectRequired]"
                          :disabled="shouldDisable"
                        >
                        </v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          :items="t_funzionalita"
                          return-object
                          item-text="cDescrizione"
                          item-value="id"
                          :label="$t('funzionalita')"
                          v-model="attrezzaturaSel.tipoAttrezFunz"
                          :disabled="shouldDisable"
                        >
                        </v-select>
                      </v-col>
                    </v-col>
                    <v-col>
                      <v-col>
                        <v-select
                          :items="eUtentiResp"
                          return-object
                          item-text="cNomeCognome"
                          item-value="id"
                          :label="$t('attrezzature.responsabile')"
                          v-model="attrezzaturaSel.utenteRespDTO"
                          :rules="[rules.selectRequired]"
                          :disabled="shouldDisable"
                        >
                        </v-select>
                      </v-col>
                    </v-col>

                    <v-col>
                      <v-textarea
                        v-model="attrezzaturaSel.xNote"
                        outlined
                        name="txtNote"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card>

                <CompDocuments
                  v-if="item == 'Documenti'"
                  :eDocumenti="attrezzaturaSel.documenti"
                  :t_documenti="t_documenti"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-document="loadDocument"
                  v-on:delete-document="deleteDocument"
                />

                <CompScadenze
                  v-if="item == 'Scadenze'"
                  :eScadenze="attrezzaturaSel.scadenze"
                  :t_tiposcadenze="t_tiposcadenze"
                  :t_tipointerventi="t_tipointerventi"
                  :t_conteggi="t_conteggi"
                  :t_statoscadenza="t_statoscadenza"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-scadenza="loadScadenza"
                  v-on:delete-scadenza="deleteScadenza"
                />
              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-btn
                v-show="!isAttrezzaturaStorico"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                v-show="!isAttrezzaturaStorico"
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>
              <v-btn
                v-show="!isAttrezzaturaStorico"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = !isHidden"
                @click="onDuplicaClick"
              >
                <v-icon left> mdi-content-duplicate </v-icon>
                {{ $t("duplica") }}
              </v-btn>
              <v-btn
                v-show="!isAttrezzaturaStorico"
                tile
                color="darkred"
                v-if="isDisattivaEnabled"
                @click="onDisattivaClick"
              >
                <v-icon left> mdi-delete </v-icon>
                {{ $t("disattiva") }}
              </v-btn>
              <v-btn tile color="darkred" @click="closeDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
              <v-dialog v-model="dialogDisattivaAttrezzatura" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler disattivare l'attrezzatura selezionata?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDisattivaAttrezzatura"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDisattivaAttrezzatura"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import { environment } from "@/environment/environment";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";
import AttrezzatureService from "@/service/AttrezzatureService";
import ExcelService from "@/service/ExcelService";

import CompDocuments from "@/components/CompDocuments";
import CompScadenze from "@/components/CompScadenze";

@Component({
  components: {
    CompDocuments,
    CompScadenze,
  },
})
export default class Attrezzature extends Vue {
  attrezzature = {
    select: [],
    selected: null,
    source: [],
  };

  rules = Utils.rules;

  attrezzatureTabs = ["Anagrafica", "Documenti", "Scadenze"];

  headersColumnsOrig = [
    {
      text: "Sigla",
      align: "start",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Marca", value: "marcaModello" },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "Ubicazione", value: "ubicazioneDTO.cDescrizione" },
    { text: "Ente proprietario", value: "enteDTO.cDescrizione" },
  ];

headersColumnsStorico = [
    {
      text: "Sigla",
      align: "start",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Marca", value: "marcaModello" },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "Ubicazione", value: "ubicazioneDTO.cDescrizione" },
    { text: "Ente proprietario", value: "enteDTO.cDescrizione" },
    { text: "Disattivato", value: "Disattivo" },
  ];

  headersColumnsRidotte = [
    {
      text: "Sigla",
      align: "start",
      value: "cSigla",
    },
    { text: "Inventario", value: "nInventario" },
    { text: "Marca", value: "marcaModello" },
    { text: "Descrizione", value: "cDescrizione" },
  ];

  eCapitoliSpesa = [];

  eUbicazioni = [];

  eUtentiResp = [];

  eEnti = [];

  t_funzionalita = [];
  t_funzionalitaOrig = [];

  t_documenti = [];
  t_documentiOrig = [];

  t_tiposcadenze = [];
  t_tiposcadenzeOrig = [];

  t_tipointerventi = [];
  t_tipointerventiOrig = [];

  t_conteggi = [];
  t_conteggiOrig = [];

  t_statoscadenza = [];
  t_statoscadenzaOrig = [];

  // Non usata
  t_scad_categoria = [];

  // Variabili globali
  tab = null;
  isEditattrezzatura = false;
  isAttrezzaturaStorico = false;
  attrezzaturaSel = null;
  attrezzaturaTemplate = null;
  attrezzaturaDupl = null;
  dimension = 0;
  shouldDisable = true;
  enteShouldDisable = true;
  isHidden = false;
  search = "";
  dialogDisattivaAttrezzatura = false;
  alertType = "error";
  messaggioAlert = "";
  isloading_attrezzature = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;
  saveClicked = false;
  visualizzaStorico = false;
  isCreatedCorrectly = false;
  isDisattivaEnabled = false;

  mounted() {
    if (this.isCreatedCorrectly) this.onContainerChange();
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_ATTREZZATURE");
    switch (verLoadingPage) {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        var enteId = this.$store.state.enteSel.id;
        if (this.$store.state.roleSel.CCode == "ADM") 
          this.isDisattivaEnabled = true;
        else
          this.isDisattivaEnabled = false;
        // Caricamento oggetto principale
        this.loadAttrezzature(enteId, this.visualizzaStorico);
        this.loadEnti();

        // Tutte le tipologiche
        this.loadAllTipologiche();
        //this.loadEnteAndLinkObjects(enteId);

        // Mi faccio restituire i template
        this.getTemplateAttrezzatura();
        break;

      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else 
    {
      if (!this.visualizzaStorico)
        return this.headersColumnsOrig;
      else
        return this.headersColumnsStorico
    }
  }

  onTableRowClick(attrezzatura, row) {
    // Setto la riga selezionata
    this.attrezzaturaSel = attrezzatura;
    this.loadAttrezzatura(this.attrezzaturaSel.id, this.onContainerChange);
    this.loadEnti();
    console.log("Attrezzatura selezionata: ");
    console.log(this.attrezzaturaSel);
    
    if (!this.isEditattrezzatura)
      this.tab = "Anagrafica";

    // Carico le tipologiche dell'elemento selezionato
    this.loadAllTipologicheAttive(false);
    this.fillTipologicheERelazioni();

    this.onContainerChange();
    this.shouldDisable = true;
    this.shouldDisableScad = true;
    this.isHidden = false;
    this.isEditAttrezzatura = true;

    if (this.attrezzaturaSel.hDel != null) this.isAttrezzaturaStorico = true;
    else this.isAttrezzaturaStorico = false;

    this.manageEnteVisibility(false);
    this.isEditattrezzatura = true;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMainAttrezz").offsetWidth;
      return self.dimension;
    });
  }

  onEnteChanged(item) {
    this.loadEnti();
    this.loadEnteAndLinkObjects(this.attrezzaturaSel.enteDTO.id);
  }

  onExportGrid() {
    var enteId = this.$store.state.enteSel.id;
    ExcelService.generaExcelAttrezzature(
      enteId,
      this.visualizzaStorico,
      function (data, resp) {
        const blob = new Blob([data]);
        const el = document.createElement("a");
        el.href = window.URL.createObjectURL(blob);
        const regex = /filename=(?<filename>[^,;]+);/g;
        const match = regex.exec(resp.headers["content-disposition"]);
        let filename = ""; // or any other value you consider default
        if (typeof match !== "undefined" && match !== null) {
          filename = match.groups["filename"];
        }
        el.download = filename;
        el.click();
      },
      (fail) => {
        alert(fail);
      }
    );
  }

  onNuovaAttrezzatura() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.$refs.form.resetValidation();
    this.saveClicked = false;

    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);
    // Caricamento oggetti principali in relazione
    this.loadEnti();
    //this.loadEnteAndLinkObjects(idEnte);
    // Impostazione variabili
    this.isEditattrezzatura = true;
    this.shouldDisable = false;
    this.isHidden = true;
    // Svuoto oggetti collegati
    this.eCapitoliSpesa = [];
    this.eUtentiResp = [];
    this.eUbicazioni = [];
    
    this.onContainerChange();
    
    this.attrezzaturaSel = Object.assign({}, this.attrezzaturaTemplate);
    this.attrezzaturaSel.documenti = [];
    
    if (this.$store.state.enteSel.id > 0) 
      this.attrezzaturaSel.enteDTO = this.$store.state.enteSel;
    this.onEnteChanged();

    this.tab = "Anagrafica";

    this.manageEnteVisibility(true);
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      var data = new Date();
      var aaaa = data.getFullYear();
      if (this.attrezzaturaSel.nAnnoAcquisto > aaaa)
      {
        this.alertType = "error";
        // In caso di errore del salvataggio continuare con l'editing
        this.messaggioAlert = i18n.t("erroreAnnoAcquisto");
        this.showHide_alertDetail(8000);
        return;
      }

      if (this.attrezzaturaSel.id > 0)
        // Salvo il dato sul DB
        this.saveAttrezzaturaDto(this.attrezzaturaSel.id);
      // Creo il nuovo record
      else this.createAttrezzaturaDto();
    }
    this.manageEnteVisibility(false);
  }

  onEditClick() {
    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);
    // Caricamento oggetti principali in relazione
    this.loadEnti();
    //this.loadEnteAndLinkObjects(idEnte);

    this.loadAttrezzatura(this.attrezzaturaSel.id, this.onContainerChange);
    this.manageEnteVisibility(true);
    
    this.shouldDisable = false;
  }

  onDuplicaClick() {
    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);

    // Caricamento oggetti principali in relazione
    //this.loadEnteAndLinkObjects(idEnte);
    //this.loadEnti();

    var attrezzaturaDupl = Object.assign({}, this.attrezzaturaSel);

    // Azzero parametri da azzerare
    attrezzaturaDupl.cSigla = "";
    attrezzaturaDupl.nInventario = "";
    attrezzaturaDupl.id = -1;

    attrezzaturaDupl.scadenze = [];
    attrezzaturaDupl.documenti = [];
    // Riassegno il dato bindato
    this.attrezzaturaSel = attrezzaturaDupl;
    this.$refs.form.resetValidation();

    this.manageEnteVisibility(true);
    this.shouldDisable = false;
  }

  onKeyDownDecimalAndNegative(key) {
    if (key.key == "." || key.key == "," || key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onDisattivaClick() {
    this.dialogDisattivaAttrezzatura = true;
  }

  confirmDisattivaAttrezzatura() {
    this.disattivaAttrezzaturaDto(this.attrezzaturaSel.id);
    this.dialogDisattivaAttrezzatura = false;
  }

  closeDisattivaAttrezzatura() {
    this.dialogDisattivaAttrezzatura = false;
  }

  getTabSelected(tabId) {
    if (this.saveClicked) this.$refs.form.validate();
  }

  closeDetail() {
    this.isEditattrezzatura = false;
    this.onContainerChange();
  }

  get gridItemActions() {
    return `item.actions`;
  }

  manageEnteVisibility(isEditing)
  {
    // Gestione Utente / Profilazione
    if (this.$store.state.roleSel != null) {
      if (this.$store.state.roleSel.CCode == "ADM") {
        if (this.$store.state.enteSel.id == 0) {
          if (isEditing == true)
            // Può selezionare
            this.enteShouldDisable = false;
          else
            // Disabilito le select degli enti
            this.enteShouldDisable = true;
        } else {
          // E' bloccato come un utente qualsiasi
          // Setto l'ente selezionato in Dashboard
          if (!Utils.isNullOrUndefined(this.attrezzaturaSel)) {
            this.attrezzaturaSel.enteDTO = this.$store.state.enteSel;
            // Disabilito le select degli enti
            this.enteShouldDisable = true;
          }
        }
      } else {
        if (!Utils.isNullOrUndefined(this.attrezzaturaSel)) {
          this.attrezzaturaSel.enteDTO = this.$store.state.enteSel;

          // Disabilito le select degli enti
          this.enteShouldDisable = true;
        }
        // Disabilito le select degli enti
        this.enteShouldDisable = true;
      }
    }
  }

  checkStorico(e) {
    var enteId = this.$store.state.enteSel.id;

    // Caricamento oggetto principale
    this.loadAttrezzature(enteId, this.visualizzaStorico);
    this.closeDetail();
  }

  fillTipologicheERelazioni() {
    // Tipologiche
    //this.t_funzionalita = [this.attrezzaturaSel.tipoAttrezFunz];
    // Relazioni
    //this.eEnti = [this.attrezzaturaSel.enteDTO];
    
    
    //this.eUtentiResp = [this.attrezzaturaSel.utenteRespDTO];
    //this.eCapitoliSpesa = [this.attrezzaturaSel.capitoloSpesaDTO];
    //this.eUbicazioni = [this.attrezzaturaSel.ubicazioneDTO];

    for (let i = 0; i < this.attrezzaturaSel.documenti.length; i++) {
      var item = this.attrezzaturaSel.documenti[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }
    for (let i = 0; i < this.attrezzaturaSel.scadenze.length; i++) {
      var item = this.attrezzaturaSel.scadenze[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }
  }

  loadAllTipologiche() {
    // Caricamento di tutte le Tipologiche
    // Attrezzature
    this.loadTAttrezFunz();
    // Documenti
    this.loadTDocumenti();
    // Scadenze
    this.loadTScadenzaTipo();
    this.loadTInterventoTipo();
    this.loadTScadenzaConteggio();
    this.loadTScadenzaStato();
  }

  loadAllTipologicheAttive(soloAttive) {
    // Caricamento di tutte le Tipologiche
    if (soloAttive)
    {
      // SOLO le tipologiche attive

      // Attrezzature
      this.t_funzionalita = Utils.getTipologicheAttive(this.t_funzionalitaOrig);
      // Documenti
      this.t_documenti = Utils.getTipologicheAttive(this.t_documentiOrig);
      // Scadenze
      this.t_tiposcadenze = Utils.getTipologicheAttive(this.t_tiposcadenzeOrig);
      this.t_tipointerventi = Utils.getTipologicheAttive(this.t_tipointerventiOrig);
      this.t_conteggi = Utils.getTipologicheAttive(this.t_conteggiOrig);
      this.t_statoscadenza = Utils.getTipologicheAttive(this.t_statoscadenzaOrig);
    }
    else
    {
      // TUTTE le tipologiche

      // Attrezzature
      this.t_funzionalita = this.t_funzionalitaOrig;
      // Documenti
      this.t_documenti = this.t_documentiOrig;
      // Scadenze
      this.t_tiposcadenze = this.t_tiposcadenzeOrig;
      this.t_tipointerventi = this.t_tipointerventiOrig;
      this.t_conteggi = this.t_conteggiOrig;
      this.t_statoscadenza = this.t_statoscadenzaOrig;
    }
  }

  showAlertMessage(alertType, localVariableName) {
    this.alertType = alertType;
    this.messaggioAlert = i18n.t(localVariableName);
    if (alertType == "error")
      this.showHide_alertDetail(8000);
    else
      this.showHide_alertDetail(5000);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      //console.log("hide alert after " + millisecondInterval / 1000 + " seconds");
    }, millisecondInterval);
  }

  loadScadenza(scadenzaTemp) {
    if (scadenzaTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      scadenzaTemp.guid = Utils.createGuid();
      if (this.attrezzaturaSel.id > 0)
        scadenzaTemp.eAttrezzatureId = this.attrezzaturaSel.id;
      this.attrezzaturaSel.scadenze.push(scadenzaTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.attrezzaturaSel.scadenze.length; i++) {
        var item = this.attrezzaturaSel.scadenze[i];
        if (item.guid == scadenzaTemp.guid) {
          item = scadenzaTemp;
        }
      }
    }
  }

  deleteScadenza(guid) {
    for (let i = 0; i < this.attrezzaturaSel.scadenze.length; i++) {
      var item = this.attrezzaturaSel.scadenze[i];
      if (item.guid == guid) {
        if (item.id == 0)
          this.attrezzaturaSel.scadenze.splice(i, 1);
        else
          item.hDel = new Date();
        break;
      }
    }
  }

  loadDocument(docTemp) {
    if (docTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      docTemp.guid = Utils.createGuid();
      if (this.attrezzaturaSel.id > 0)
        docTemp.objectId = this.attrezzaturaSel.id;
      this.attrezzaturaSel.documenti.push(docTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.attrezzaturaSel.documenti.length; i++) {
        var item = this.attrezzaturaSel.documenti[i];
        if (item.guid == docTemp.guid) {
          item = docTemp;
        }
      }
    }
  }

  deleteDocument(guid) {
    for (let i = 0; i < this.attrezzaturaSel.documenti.length; i++) {
      var item = this.attrezzaturaSel.documenti[i];
      if (item.guid == guid) {
        item.hDel = new Date();
        break;
      }
    }
  }

  // *******************
  // DATABASE
  // *******************
  loadAttrezzature(enteId, storico) {
    var self = this;
    self.isloading_attrezzature = true;

    AttrezzatureService.getAttrezzature(
      enteId,
      storico,
      (resp) => {
        self.isloading_attrezzature = false;
        console.log("Attrezzature:");
        console.log(resp);

        self.attrezzature.source = resp;
        for (let i = 0; i < self.attrezzature.source.length; i++) {
          var item = self.attrezzature.source[i];
          if (item.hDel != null) 
            item.Disattivo = "Si";
          else
            item.Disattivo = "No";
        }

      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("getAttrezzature");
        self.showHide_alertGrid(5000);
        self.isloading_attrezzature = false;
      }
    );
  }

  loadAttrezzatura(id, onSuccess) {
    var self = this;

    AttrezzatureService.getAttrezzatura(
      id,
      (resp) => {
        console.log("Attrezzatura:");
        console.log(resp);

        self.attrezzaturaSel = resp;

        for (let i = 0; i < self.attrezzaturaSel.documenti.length; i++) {
          var item = self.attrezzaturaSel.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        for (let i = 0; i < self.attrezzaturaSel.scadenze.length; i++) {
          var item = self.attrezzaturaSel.scadenze[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(5000);
        //self.isloading_fornitori = false;
      }
    );
  }

  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;
        if (!Utils.isNullOrUndefined(self.attrezzaturaSel))
          self.loadEnteAndLinkObjects(self.attrezzaturaSel.enteDTO.id);

      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  /*
  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;
      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }
*/
  loadEnteAndLinkObjects(idEnte) {
    var self = this;

    EntiService.getEnteAndLinkObjects(
      idEnte,
      (resp) => {
        console.log("GetEnteAndLinkObjects");
        console.log(resp);
        //self.eServizi = resp.serviziEnte;
        self.eCapitoliSpesa = resp.capitoliSpesaEnte;
        self.eUtentiResp = resp.utentiRespEnte;
        self.eUtentiUtiliz = resp.utentiUtilizEnte;
        self.eUbicazioni = resp.ubicazioniEnte;
        //self.eEnti = [resp];
      },
      (err) => {
        console.log("Errore GetEnteAndLinkObjects");
        console.log(err);
      }
    );
  }

  createAttrezzaturaDto() {
    var self = this;

    AttrezzatureService.createAttrezzatura(
      self.attrezzaturaSel,
      (resp) => {
        console.log("CreateAttrezzatura");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          // Ricarico la griglia
          this.checkStorico(false);
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err.response.data.userMsg.message);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  saveAttrezzaturaDto(id) {
    var self = this;

    AttrezzatureService.updateAttrezzatura(
      self.attrezzaturaSel,
      (resp) => {
        console.log("UpdateAttrezzatura");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          for (let i = 0; i < self.attrezzature.source.length; i++) {
            var item = self.attrezzature.source[i];
            if (item.id == id) {
              self.attrezzature.source.splice(i, 1);
              self.attrezzature.source.push(self.attrezzaturaSel);
            }
          }
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  disattivaAttrezzaturaDto(id) {
    var self = this;

    AttrezzatureService.deleteAttrezzatura(
      self.attrezzaturaSel.id,
      (resp) => {
        console.log("deleteAttrezzatura");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          this.alertType = "success";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertGrid(5000);
          // Azzero l'oggetto
          this.attrezzaturaSel = null;
          // Ricarico la griglia
          this.checkStorico(false);
        } else {
          // KO
          this.alertType = "error";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  // ************************
  // TIPOLOGICHE SCADENZE
  // ************************
  loadTScadenzaTipo() {
    var self = this;

    TipologicheService.getTScadenzaTipo(
      (resp) => {
        console.log("GetTScadenzaTipo");
        console.log(resp);

        self.t_tiposcadenze = resp;
        self.t_tiposcadenzeOrig = Utils.cloneArray(self.t_tiposcadenze);
      },
      (err) => {
        console.log("Errore GetTScadenzaTipo");
        console.log(err);
      }
    );
  }

  loadTScadenzaStato() {
    var self = this;

    TipologicheService.getTScadenzaStato(
      (resp) => {
        console.log("GetTScadenzaStato");
        console.log(resp);

        self.t_statoscadenza = resp;
        self.t_statoscadenzaOrig = Utils.cloneArray(self.t_statoscadenza);
      },
      (err) => {
        console.log("Errore GetTScadenzaStato");
        console.log(err);
      }
    );
  }

  loadTScadenzaConteggio() {
    var self = this;

    TipologicheService.getTScadenzaConteggio(
      (resp) => {
        console.log("GetTScadenzaConteggio");
        console.log(resp);

        self.t_conteggi = resp;
        self.t_conteggiOrig = Utils.cloneArray(self.t_conteggi);
      },
      (err) => {
        console.log("Errore GetTScadenzaConteggio");
        console.log(err);
      }
    );
  }

  loadTScadenzaCategoria() {
    var self = this;

    TipologicheService.getTScadenzaCategoria(
      (resp) => {
        console.log("GetTScadenzaCategoria");
        console.log(resp);

        self.t_scad_categoria = resp;
      },
      (err) => {
        console.log("Errore GetTScadenzaCategoria");
        console.log(err);
      }
    );
  }

  loadTInterventoTipo() {
    var self = this;

    TipologicheService.getTInterventoTipo(
      (resp) => {
        console.log("GetTInterventoTipo");
        console.log(resp);

        self.t_tipointerventi = resp;
        self.t_tipointerventiOrig = Utils.cloneArray(self.t_tipointerventi);
      },
      (err) => {
        console.log("Errore GetTInterventoTipo");
        console.log(err);
      }
    );
  }

  loadTAttrezFunz() {
    var self = this;

    TipologicheService.getTAttrezFunz(
      (resp) => {
        console.log("GetTAttrezFunz");
        console.log(resp);

        self.t_funzionalita = resp;
        self.t_funzionalitaOrig = Utils.cloneArray(self.t_funzionalita);
      },
      (err) => {
        console.log("Errore GetTAttrezFunz");
        console.log(err);
      }
    );
  }

  loadTDocumenti() {
    var self = this;

    TipologicheService.getTDocumentoAttrezzaturaAll(
      (resp) => {
        console.log("getTDocumentoAttrezzaturaAll");
        console.log(resp);

        self.t_documenti = resp;
        self.t_documentiOrig = Utils.cloneArray(self.t_documenti);
      },
      (err) => {
        console.log("Errore getTDocumentoAttrezzaturaAll");
        console.log(err);
      }
    );
  }

  /// ***************************
  // TEMPLATE
  /// ***************************
  getTemplateAttrezzatura() {
    var self = this;

    AttrezzatureService.getTemplateAttrezzatura(
      (resp) => {
        console.log("getTemplateAttrezzatura:");
        console.log(resp);
        self.attrezzaturaTemplate = resp;
        // Inizializzazione variabili non settate
        self.attrezzaturaTemplate.id = 0;
        self.attrezzaturaTemplate.cSigla = "";
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }
}
</script>

<style>
</style>
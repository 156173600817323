import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Scadenze/';

class ScadenzeService {
    getTemplateScadenza(successFn, failFn){
        UtilsRequest.get(API_URL + "GetTemplateScadenza", null,
        successFn,
        failFn
        );
    }

    getScadenzeAttive(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idEnte", enteId);
        UtilsRequest.get(API_URL + "GetScadenzeAttive", parametri,
        successFn,
        failFn
        );
    }

    cambiaDataScadenza(idScad, nuovaDataScadenza, successFn, failFn){
        var idScadNuovaData = idScad + ";" + nuovaDataScadenza;
        UtilsRequest.put(API_URL + "CambiaDataScadenza", idScadNuovaData,
        successFn,
        failFn
        );
    }
    
}

export default new ScadenzeService();